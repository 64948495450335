class SimpleImageGallery {
  constructor(container) {
      this.container = container
      this.mainImage = container.querySelector('.simple-image-gallery-main-image')
      this.thumbnails = Array.from(container.querySelectorAll('.simple-image-gallery-thumb-image'))
      this.currentIndex = this.thumbnails.findIndex((thumb) =>
          thumb.classList.contains('opacity-100')
      )

      // Bind event listeners
      this.thumbnails.forEach((thumb, index) => {
          thumb.addEventListener('click', () => this.updateMainImage(index))
      })

      const leftArrow = container.querySelector('.rotate-90')
      const rightArrow = container.querySelector('.-rotate-90')
      if (leftArrow) leftArrow.addEventListener('click', () => this.navigate(-1))
      if (rightArrow) rightArrow.addEventListener('click', () => this.navigate(1))
  }

  updateMainImage(index) {
      // Update current index
      this.currentIndex = index

      // Update main image src or srcset
      const thumbnail = this.thumbnails[index]
      const attr = thumbnail.hasAttribute('srcset') ? 'srcset' : 'src'
      const value = thumbnail.getAttribute(attr)
      this.mainImage.setAttribute(attr, value)

      // Highlight the selected thumbnail
      this.thumbnails.forEach((thumb) => {
          thumb.classList.add('opacity-75')
          thumb.classList.remove('opacity-100')
      })
      thumbnail.classList.remove('opacity-75')
      thumbnail.classList.add('opacity-100')
  }

  navigate(direction) {
      // Calculate new index with looping
      const newIndex = (this.currentIndex + direction + this.thumbnails.length) % this.thumbnails.length
      this.updateMainImage(newIndex)
  }
}

// Initialize the gallery when the DOM is ready
document.addEventListener('DOMContentLoaded', () => {
  const galleries = document.querySelectorAll('.simple-image-gallery')
  galleries.forEach((gallery) => new SimpleImageGallery(gallery))
})

const simpleImageGalleryThumbnail = (function (element) {
  // Highlight the selected thumbnail
  const siblingImages = element.parentElement.parentElement.getElementsByClassName('simple-image-gallery-thumb-image')

  for (let item of siblingImages) {
      item.classList.add('opacity-75')
      item.classList.remove('opacity-100')
  }
  element.classList.remove('opacity-75')
  element.classList.add('opacity-100')

  // Find the main image element
  const cssClass = 'simple-image-gallery-main-image'
  const mainImages = element.parentElement.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(cssClass)

  // Set the main image 'srcset' or 'src'
  const attr = element.hasAttribute('srcset') ? 'srcset' : 'src'
  const value = element.getAttribute(attr)
  for (let image of mainImages) {
      image.setAttribute(attr, value)
  }
})

window.simpleImageGalleryThumbnail = simpleImageGalleryThumbnail
